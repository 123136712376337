import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeENGB from '@angular/common/locales/en-GB';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthInterceptor } from './interceptors/http.interceptor';
import { JwtModule } from '@auth0/angular-jwt';
import { environment } from '../environments/environment';
import { TemplateTranslationService } from './services/template-translation.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { Helpers } from './shared/components/helpers';
import { ToastrModule } from 'ngx-toastr';
import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { MicrosoftLoginProvider } from '@abacritt/angularx-social-login';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'https://api.kiwaimpact.com/v2/translations/?lang=', '');
}

registerLocaleData(localeENGB);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: (request) => {
          if (request.headers.has('no-token')) {
            return null;
          }
          return localStorage.getItem('impact-token');
        },
        allowedDomains: environment.allowedDomains,
        disallowedRoutes: [/no-token/],
      }
    }),
    ToastrModule.forRoot(),
    SocialLoginModule,
  ],
  providers: [
    TemplateTranslationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    TranslateService,
    { provide: LOCALE_ID, useValue: 'en-gb' }, // https://stackoverflow.com/questions/34904683/how-to-set-locale-in-datepipe-in-angular2
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: MicrosoftLoginProvider.PROVIDER_ID,
            provider: new MicrosoftLoginProvider(
              environment.oauthData.microsoftOauth.clientId, {
                redirect_uri: environment.oauthData.microsoftOauth.redirectUri,
                logout_redirect_uri: environment.oauthData.microsoftOauth.logoutRedirectUri
              }
            )
          },
        ]
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(translate: TranslateService) {
    translate.setDefaultLang('en');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.language) {
      translate.use(user.language.code);
      Helpers.changeLocale(user.language.code);
    }
  }
}
