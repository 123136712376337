import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

interface TranslationCacheItem {
  translation_id: number;
  language_id: number;
  template_id: number;
  language_code: string;
  language_name: string;
  translations: any[];
}

@Injectable({
  providedIn: 'root'
})
export class TemplateTranslationService {

  private isLoadingTranslations = new Map<number, boolean>();
  private translationCache: TranslationCacheItem[] = [];

  constructor(
    private http: HttpClient
  ) {}

  getCachedTranslation(key: string, translationId: number, templateId: number): string | null {
    const templateCache = this.translationCache.find(i => i.template_id === templateId && i.translation_id === translationId);
    // console.log(`Trying to get key:${key} with translationId:${translationId} and templateId:${templateId} from:`, this.translationCache);
    return templateCache?.translations[key] ? templateCache.translations[key] : key;
  }

  translate(key: string, translationId: number, templateId: number): string | null {
    const cachedTranslation = this.getCachedTranslation(key, translationId, templateId);
    if (cachedTranslation !== null) {
      return cachedTranslation;
    }

    if (!this.isLoadingTranslations.get(templateId)) {
      this.loadTranslations(templateId).then(() => {
        console.log('Translations for template id:' + templateId + ' loaded!');
      });
    }

    return this.getCachedTranslation(key, translationId, templateId);
  }


  clearCache() {
    // this.translationCache = [];
  }

  private loadTranslations(templateId: number): Promise<any> {
    // Check if loading is already in progress for this templateId
    if (this.isLoadingTranslations.get(templateId)) {
      // If loading is already in progress, return a resolved promise
      return Promise.resolve();
    }

    // Set the loading flag to true
    this.isLoadingTranslations.set(templateId, true);
    return this.http
      .get(`${environment.apiUrl}templates/${templateId}/translations`)
      .toPromise()
      .then((response: any) => {
        // Loading is complete, set the flag to false
        this.isLoadingTranslations.set(templateId, false);
        this.processTranslations(response.data);
        return response.data;
      }).catch((err) => {
        return this.handleError(err, templateId);
      });
  }

  private processTranslations(translationsData: any[]): void {
    translationsData.forEach(translationObj => {
      if (translationObj && translationObj.translations) {
        try {
          // Parse the JSON string to an object
          const translations = JSON.parse(translationObj.translations);
          const cached = this.translationCache.find(tc => tc.translation_id === translationObj.id);
          if (!cached) {
            this.translationCache.push({
              translation_id: translationObj.id,
              language_id: translationObj.language_id,
              template_id: translationObj.template_id,
              language_code: translationObj.language?.code || null,
              language_name: translationObj.language?.name || null,
              translations: translations
            });
          } else {
            cached.translations = translations;
          }
        } catch (error) {
          console.error('Error parsing translations JSON:', error);
        }
      }
    });
  }


  getTemplateTranslations(id): Promise<any> {
    return this.loadTranslations(id);
  }


  getTemplateTranslationsWithLangs(templateId: number): Promise<any> {
    return this.loadTranslations(templateId).then(() => {
      return this.augmentTranslationsWithLanguageDetails(templateId);
    });
  }

  private augmentTranslationsWithLanguageDetails(templateId: number): Promise<any[]> {
    const augmentedTranslations = [];

    // Iterate through each translation in the cache
    this.translationCache.filter(i => i.template_id === templateId).forEach((translationCacheItem) => {

      const safeTranslations = translationCacheItem.translations || {};

      // Check if the translations object is completely empty
      const isComplete = Object.keys(safeTranslations).length > 0 && !Object.values(safeTranslations).some(value => value === '');
      const translationWithLang = {
        translations: safeTranslations,
        language_name: translationCacheItem.language_name || null,
        language_code: translationCacheItem.language_code || null,
        complete: isComplete,
        language_id: translationCacheItem.language_id,
        id: translationCacheItem.translation_id
      };

      augmentedTranslations.push(translationWithLang);
    });
    return Promise.resolve(augmentedTranslations);
  }





  private handleError(error: any, templateId = null): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    if (templateId) {
      // Error, set the download flag to false
      this.isLoadingTranslations.set(templateId, false);
    }
    return Promise.reject(error.message || error);
  }

  createTemplateTranslation(id, params): Promise<any> {
    return this.http
      .post(environment.apiUrl + 'templates/' + id + '/translations', params)
      .toPromise()
      .then((response: any) => response.data)
      .catch(this.handleError);
  }

  updateTemplateTranslation(id, translationId, params): Promise<any> {
    return this.http
      .put(environment.apiUrl + 'templates/' + id + '/translations/' + translationId, params)
      .toPromise()
      .then((response: any) => response.data)
      .catch(this.handleError);
  }

  deleteTemplateTranslation(id, translationId): Promise<any> {
    return this.http
      .delete(`${environment.apiUrl}templates/${id}/translations/${translationId}`)
      .toPromise()
      .then((response: any) => response.data)
      .catch(this.handleError);
  }

}
