import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private userService: UserService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: any) => {
        if ((error.status === 400 && error.error && (error.error.error.code === 'Invalid token, try to login')) ||
          (error.status === 400 && error.error && (error.error.error.code === 'token_not_provided')) ||
          (error.status === 401 && error.error && (error.error.error.code === 'token_invalid'))) {
          localStorage.clear();
          this.userService.authUser = null;
          // Only reload the page if we are not on login page
          if (!this.router.url.includes('/login')) {
            location.reload();
          }
        }
        return throwError(error);
      }),
    );
  }
}

