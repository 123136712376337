import { Injectable } from '@angular/core';
import {AuthService} from './auth.service';
import {MeModel} from '../models/me.model';
import { Helpers } from '../shared/components/helpers';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(
    private auth: AuthService,
  ) { }

  getCurrentUserDetails(): MeModel {
    return JSON.parse(localStorage.getItem('user'));
  }

  isOrganisationAdmin() {
    const userData = JSON.parse(localStorage.getItem('user'));
    return this.auth.isAuthenticated() && userData.orgaAdmin && userData.orgaAdmin === 1;
  }

  isInstaAdmin() {
    const userData = JSON.parse(localStorage.getItem('user'));
    return this.auth.isAuthenticated() && userData.instaAdmin && userData.instaAdmin === 1;
  }

  isAdmin(): boolean {
    const userData = JSON.parse(localStorage.getItem('user'));
    return Helpers.hasPermission(userData.permissionItems, 'default-admin', 'generic');
  }
  userHasOnlyChemicals () {
    const userData = JSON.parse(localStorage.getItem('user'));
    const navigationItems = userData?.navigation;
    let onlyChemicals = false;
    if (navigationItems) {
      for (const item of navigationItems) {
        if (item.name === 'Chemicals' || item.name === 'Tasks' || item.name === 'Chemical Admin' || item.name === 'Admin') {
          onlyChemicals = true;
        } else {
          onlyChemicals = false;
          break;
        }
    }
    }
    return onlyChemicals;
  }
  hasPermissionToLevelAndModule(levelId: number, moduleString: string) {
    const userData = JSON.parse(localStorage.getItem('user'));
    if (this.auth.isAuthenticated()) {
      if (userData.permissionItems) {
        const levelPermissions = userData.permissionItems[levelId];
        return levelPermissions.includes(moduleString);
      }
    }
    return false;
  }
}
