export const environment = {
  envName: 'release',
  production: false,
  apiUrl: 'https://api.impact.release.kiwa.dev/v2/',
  frontUrl: 'https://impact.release.kiwa.dev/',
  analyticsApiUrl: 'https://api.impact.release.kiwa.dev/v2/',
  kmUrl: 'https://km.release.kiwa.dev/',
  demoAccountId: 1,
  allowedDomains: ['api.impact.release.kiwa.dev', 'api.km.dev.kiwa.dev'],
  disallowedDomains: [],
  googleMapsKey: 'AIzaSyANXjyEQwRSXKnQiiSuLoqA4k6e0oEs1II',
  oauthData: {
    microsoftOauth: {
      clientId: '830f8a4e-36e5-443f-923c-245e29bde96b',
      redirectUri: 'https://impact.release.kiwa.dev/login',
      logoutRedirectUri: ''
    }
  },
  amplitudeKey: '9c920136012a535adc1622fed41f5e20',
  mawBucket: 'https://s3.eu-west-1.amazonaws.com/kita-dev-processed.kiwaimpact.com/',
  lessSecureAnalytics: [],
};
